<footer class="text-lg-start">
  <div *ngIf="showTopFooter" class="top-footer">
    <!-- Grid container -->
    <ds-themed-text-section *ngIf="hasSiteFooterSections" [sectionId]="'site'" [textRowSection]=section [site]="(site | async)"></ds-themed-text-section>
    <div class="py-5">
      <div class="container">
        <table class="w-100">
          <tr>
            <td width="50%"  align="left">
              <img src="assets/images/UDD-blanco.png" width="200px" alt="Logo Universidad de Desarrollo" >
            </td>
            <td width="50%"  align="right">
              <div class="d-flex align-items-center justify-content-end">
                <span class="text-white mr-2" style="font-size: 18px;">  {{ 'footer.hostingandsupport' | translate}}  </span>
                <img src="assets/images/scimago-logo.png" width="200px" alt="Logo Scimago" >
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer py-3 d-flex justify-content-center align-items-center text-white">
    <div class="content-container">
      <p class="m-0">
        {{ 'footer.built-with' | translate }}
        <a class="text-white" href="https://wiki.lyrasis.org/display/DSPACECRIS/">{{ 'footer.link.dspace-cris' | translate}}</a>
        {{ 'footer.extension-by' | translate }}
        <a class="text-white" href="https://www.4science.it/en/">
          <img src="assets/images/logo-4science-small.png" alt="{{ 'footer.link.4science' | translate}}" />
        </a>
      </p>
      <ul class="footer-info list-unstyled small d-flex justify-content-center mb-0 mt-1">
        <li>
          <a class="text-white" href="javascript:void(0);"
             (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a>
        </li>
        <li>
          <a class="text-white" routerLink="info/privacy">{{ 'footer.link.privacy-policy' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/end-user-agreement">{{ 'footer.link.end-user-agreement' | translate}}</a>
        </li>
        <li>
          <a class="text-white"
             routerLink="info/feedback">{{ 'footer.link.feedback' | translate}}</a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Copyright -->
</footer>
